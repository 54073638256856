export default {
  translation: {
    'shared.french': 'English',
    'app.name': 'Plateforme Énergie par Hellio',
    'auth.hero': "Bienvenue sur\nvotre espace client Mon Accompagnateur Rénov'",
  },
  global: {
    hero: 'Bienvenue sur votre espace client',
    password: 'Mot de passe',
    email: 'E-mail',
    logout: 'Se déconnecter',
    cancel: 'Annuler',
    close: 'Fermer',
    back: 'Retour',
    required: 'Ce champ est obligatoire',
    rdv: 'Prendre rendez-vous',
    internet_connexion_error:
      "Une erreur inattendue s'est produite. Veuillez réessayer plus tard.",
    'email.required': 'Veuillez renseigner une adresse e-mail valide',
    session_expired: 'Votre session a expiré. Veuillez vous reconnecter.',
  },
  login: {
    hero: 'Connexion',
    description: 'Renseignez votre adresse email et votre mot de passe',
    forgot: 'Mot de passe oublié',
    login_error: 'Votre identifiant ou votre mot de passe est incorrect',
    submit: 'Se connecter',
  },
  first_login: {
    hero: 'Première connexion',
    description: 'Renseignez votre mot de passe',
    success: 'La création de votre mot de passe a bien été prise en compte.',
    error: "Votre lien à expiré ou n'est plus valide",
    success_description: 'Création de votre mot de passe',
    success_action: 'Se connecter',
    submit: 'Valider',
    password: 'Mot de passe',
    same: 'Les mots de passes ne sont pas identiques',
    'confirm.password': 'Confirmer mot de passe',
    'confirm.regex.error':
      'Le mot de passe doit contenir 8 caractères :\nune majuscule, un chiffre et un caractère spécial.',
    'confirm.placeholder.password': 'Confirmation du mot de passe',
  },
  change_password: {
    hero: 'Réinitialisation de mot de passe',
    description: 'Veuillez renseigner votre nouveau mot de passe',
    submit: 'Valider',
    success: 'Modification du mot de passe',
    success_description: 'Votre mot de passe a bien été réinitialisé',
    success_action: 'Se connecter',
    password: 'Nouveau mot de passe',
    same: 'Les mots de passes ne sont pas identiques',
    'confirm.password': 'Confirmer mot de passe',
    'confirm.regex.error':
      'Le mot de passe doit contenir 8 caractères :\nune majuscule, un chiffre et un caractère spécial.',
    'confirm.placeholder.password': 'Confirmation du mot de passe',
  },
  404: {
    hero: 'Page introuvable',
    description: "La page que vous cherchez n'existe pas ou a été déplacée.",
    back: "Retour à l'accueil",
  },
  reset_password: {
    hero: 'Mot de passe oublié',
    description:
      'Veuillez saisir votre email de connexion afin de recevoir le lien de réinitialisation de votre mot de passe.',
    submit: 'Valider',
    error: "Votre lien à expiré ou n'est plus valide",
    back: 'Retour',
    success: 'Réinitialisation du mot de passe',
    success_alert:
      "Nous vous avons envoyé un e-mail pour réinitialiser votre mot de passe. Pour créer votre nouveau mot de passe, il vous suffit de cliquer sur le lien contenu dans l'e-mail et d'en saisir un nouveau.",
    success_description:
      "<strong>Vous n'avez pas reçu cet e-mail</strong><br> Vérifiez vos courriers indésirables ou contactez :<br>",
  },
  select: {
    all: 'Tout sélectionner',
    none: 'Tout désélectionner',
  },
  file: {
    default_label: 'Fichiers',
    delete: 'Supprimer le fichier {{file}}',
    default_drop_zone_label: 'Glissez vos fichiers ici',
    browse: 'Parcourir',
    max_size: 'Max',
    max_files_other: '{{count}} fichiers maximum',
    max_files_one: 'Un fichier maximum',
    max_files_zero: 'Aucun fichier restant',
    max_error_files: 'Vous ne pouvez pas envoyer plus de {{maxFiles}} fichiers',
    max_error_size_one:
      "Un fichier dépasse 10Mo, vous pouvez l'envoyer par email à :\n" +
      '<Link href="mailto:serviceclient.energie@hellio.com">serviceclient.energie@hellio.com</Link> avec la référence de la demande',
    max_error_size_other:
      'Certains fichiers dépassent 10Mo, vous pouvez envoyer l’envoyer par email à :\n' +
      '<Link href="mailto:serviceclient.energie@hellio.com">serviceclient.energie@hellio.com</Link> avec la référence de la demande',
    named_max_error_size_one: 'Le fichier: {{name}}, dépasse 10Mo',
    named_max_error_size_other: 'Les fichiers: {{name}}, dépassent 10Mo',
  },
  profil: {
    title: 'Mes identifiants',
    subtitle: 'Veuillez renseigner votre nouveau mot de passe',
    identity: 'Identité',
    firstname: 'Prénom',
    mobilephone: 'Téléphone mobile',
    lastname: 'Nom',
    email: 'E-mail',
    update_password: 'Modifier mot de passe',
    oldPassword: 'Ancien mot de passe',
    newPassword: 'Nouveau mot de passe',
    confirmPassword: 'Confirmer le nouveau mot de passe',
    validate: 'Valider',
    cancel: 'Annuler',
    change_password_success_title: 'Nouveau mot de passe bien enregistré',
    change_password_success_subtitle:
      'Votre changement de mot de passe a bien été pris en compte',
  },
  project: {
    details: 'Voir détails',
    owner: 'Propriétaire',
    build_date: 'Année de construction',
    surface: 'Surface',
    badge_power: 'Étiquette énergétique',
    project: 'Projet',
    documents: 'Documents',
    my_project: 'Mon projet',
    house: 'Logement',
    build_year: 'Année de construction',
    scenario: 'Scénario retenu',
  },
};
